@tailwind base;
@tailwind components;
@tailwind utilities;

/* Importación de los estilos de los componentes */
@import './components/index.css';

/* Importación de los estilos de las vista */
@import './View/index.css';

/*importación de los estilo de app*/
@import './styles/App.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  font-size: 62.5%;
  @apply bg-secondary;
}
/*New Font*/
@font-face {
  font-family: 'NovaRound';
  src: url('./assets/Font/NovaRound-Regular.ttf');
}
