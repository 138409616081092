.about__container {
  @apply grid gap-5 pl-24 my-8;
  grid-template-columns: 1fr 1fr;
}
.about__title h1 {
  @apply text-center text-7xl font-bold text-white;
  font-family: 'NovaRound', serif;
}
.about__container--content {
  @apply flex flex-col absolute;
  top: 110%;
}
.about__content--text {
  @apply text-tercer text-5xl text-justify;
  line-height: 4.5rem;
}
.about__content--img {
  @apply flex justify-center;
}
.about__content--img img {
  transition: all 1s;
}
.about__content--img img:hover {
  border-radius: 50%;
}
.about__skillls {
  @apply grid;
  grid-template-rows: 1fr 1fr;
  gap: 35px;
}
.about__skills--content {
  @apply flex justify-between items-center mx-auto mb-20;
  gap: 70px;
  width: 60%;
}
.about__skills--content-sc {
  @apply flex justify-between items-center mx-auto mb-20;
  gap: 70px;
  width: 60%;
}
.about__more {
  @apply flex justify-center m-8;
}
.about__more button {
  @apply p-4 bg-tercer text-white font-bold uppercase rounded-2xl text-4xl;
  font-family: 'NovaRound', serif;
}
/*Modal styled*/
.more__modal {
  @apply bg-secondary absolute top-1/4;
  width: 50vw;
  height: 53vh;
  left: 27%;
}
.modal__container--more h1 {
  @apply flex justify-center p-4 text-5xl uppercase text-tercer;
  font-family: 'NovaRound', serif;
}
.modal__content--it {
  @apply grid gap-5;
  grid-template-rows: 1fr 1fr;
}
.modal__content--it--img1,
.modal__content--it--img2 {
  @apply flex justify-around;
}
