h1 {
  @apply text-2xl;
}
.navbar-header {
  @apply flex justify-between items-center px-8;
}
.navbar-img img {
  @apply w-2/4 py-4;
}
/* Estilo en el drawer*/
.navbar__drawer--button {
  @apply text-8xl;
}
.drawer__content {
  @apply w-screen;
}
.drawer-content__title {
  @apply text-center pt-3 text-4xl font-bold;
}
/*Color icon*/
.drawer--icon {
  @apply text-primary;
}
